
import { defineComponent } from "vue";
import { GenericContent, paginatorService } from "@/services/paginator.service";
import PageHeader from "@/common/PageHeader.vue";

export default defineComponent({
  name: "FaqPage",
  components: { PageHeader },
  data() {
    return {
      slug: "" as string,
      item: undefined as GenericContent | undefined,
    };
  },
  mounted() {
    if (this.$route.params.slug) {
      this.slug = this.$route.params.slug as string;
      this.loadContent(this.$route.params.slug as string);
    }
  },
  methods: {
    loadContent(slug: string) {
      paginatorService.findBySlug("faq", slug).then((item) => {
        if (!item) {
          this.$router.push("/");
        } else {
          this.item = item;
        }
      });
    },
  },
  watch: {
    slug(slug) {
      if (slug) {
        this.loadContent(slug);
      } else {
        this.item = undefined;
      }
    },
  },
});
